import getOrders from "./getorders"

export const getProfilePicture = async (id) => {
    const url = `/api/user/profilepicture/${id}`
    const response = await fetch(url)
    const data = await response.json()
    if(data.error) {
        return null
    }
    return data.image;
}

export const setNewProfilePicture = async (id, image) => {
    const formData = new FormData()
    formData.append('file', image)
    const url = `${process.env.NEXT_PUBLIC_URL_API_VERCEL}/images/${id}/profile`
    const response = await fetch(url, {
        method: 'POST',
        body: formData
    })
    const data = await response.json()
    if(data.error) {
        return null
    }
    return data.url
}

export const userHasOrders = async (id) => {
    const orders = await getOrders(id, '', 10)
    orders.data = orders?.data?.filter(order => order?.order?.status !== 'canceled')
    if(orders?.data?.length > 0) {
        const lastOrderDate = new Date(orders.data[0].order.created_at)
        const dateToCompare = new Date(2024, 8, 13)
        if(lastOrderDate > dateToCompare) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}