export default async function getcode(orderId, countryId, body) {
    const response = await fetch(`/api/getcode?orderId=${orderId}&countryId=${countryId}`, {
        method: 'PATCH',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      return ({ ...data, status: response.status });
}