export const themeMercadito = {
  primary: "#FE4806",
  primaryDark:"#CB4008",
  primaryLight:"#FFDACD",
  primaryLighter:"#FFEDE7",
  secondary: "#00A656",
  secondaryDark:"#008B48",
  secondaryLight:"#99DBBB",
  secondaryLighter:"#E6F7EF",
  warning: "#FFE713",
  warningDark: "#676E10",
  warningLight: "#FFF6A1",
  warningLighter: "#FFFAD0",
  information: "#2B5DDD",
  informationDark: "#2250B4",
  informationLight: "#D5DFF8",
  informationLighter: "#EEF2FD",
  error: "#ED1F69",
  errorDark: "#8E1F45",
  errorLight: "#FBD2E1",
  errorLighter: "#FEEDF3",
  textDefault: "#001109",
  textSecondary: "#33413A",
  textTertiary: "#66706B",
  textQuaternary: "#99A09D",
  white: "#FFFFFF",
};