import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { addLocalStorage } from './localstorage';
import { localNames } from './helpers';

let analytics;

export function inicialize() {
  analytics = Analytics({
    app: 'awesome-app',
    plugins: [
      googleTagManager({
        containerId: process.env.NEXT_PUBLIC_GTM,
        preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH,
      }),
    ],
  });
}

export async function trackpage(data) {
  if (!analytics) inicialize();
  if(data)analytics.page(data).then(res => addLocalStorage(localNames.anonymousId, res.payload.anonymousId));
  else analytics.page(data).then(res => addLocalStorage(localNames.anonymousId, res.payload.anonymousId));
}

export async function track(tag, payload) {
  try {
    if (!analytics) inicialize();
    if (!tag) throw new Error('GTM track missing tag');
    if (!payload) throw new Error('GTM track missing payload');
    analytics.track(tag, {...payload, userToken: localStorage.getItem(localNames.customer_id) || localStorage.getItem(localNames.anonymousId), distance_type: localStorage.getItem('distance_type') ?? null })
    .then(res => addLocalStorage(localNames.anonymousId, res.payload.anonymousId))
  } catch (e) {
    console.log('track', e);
  }
}
