import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

const useTranslations = (locale) => {
  const [messages, setMessages] = useState();
  const importMessages = useMemo(
    () => async () => {
      try {
        const msgs = await import(`public/lang/${locale}.json`);
        setMessages(msgs);
      } catch (error) {
        setMessages({});
      }
    },
    [locale],
  );
  useEffect(() => {
    importMessages();
  }, [locale, importMessages]);

  return { messages, locale };
};

export default useTranslations;
