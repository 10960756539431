export const themeWFP = {
  primary:"#0A6EB4",
  primaryDark: "#085387",
  primaryLight: "#85b7da",
  primaryLighter: "#c2dbec",
  secondary: "#0A6EB4",
  secondaryDark:"#085387",
  secondaryLight:"#85b7da",
  secondaryLighter:"#c2dbec",
  warning: "#f7b825",
  warningDark: "#676E10",
  warningLight: "#FFF6A1",
  warningLighter: "#FFFAD0",
  information: "#2B5DDD",
  informationDark: "#2250B4",
  informationLight: "#D5DFF8",
  informationLighter: "#EEF2FD",
  error: "#c5192d",
  errorDark: "#a21942",
  errorLight: "#FBD2E1",
  errorLighter: "#FEEDF3",
  textDefault: "#031c2d",
  textSecondary: "#33413A",
  textTertiary: "#5a6872",
  textQuaternary: "#99A09D",
  white: "#FFFFFF",
};