export default async function getOrders(customer_id, last_uuid, pageSize, status) {
    const url = `/api/orders?customer_id=${customer_id}&last_uuid=${last_uuid}&page_size=${pageSize}`

    const response = await fetch(url);

    const data = await response.json()
    if(status === 'confirmed') {
        return {data: data.data.filter((order) => order.order.status === 'confirmed')}
    }
    return data
}