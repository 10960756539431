export default function nameunitmeasure(unit_measure) {
    let unit = unit_measure.toLowerCase()
    switch(unit) {
        case 'unidades':
            return 'und'
            
        case 'dozens':
            return 'docena'
        default:
            return unit
    }
}