export const getWalletBalance = async (customerId) => {
    const response = await fetch(`/api/wallet/balance?customerId=${customerId}`, {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    })
    const data = await response.json();
    return parseFloat(data?.amount?.value ?? 0)
}

export const getWalletMovements = async (customerId) => {
    const response = await fetch(`/api/wallet/movements?customerId=${customerId}`, {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    })
    const data = await response.json();
    return data;
}