export default async function sendmessage(to, message) {
  const response = await fetch(`/api/sendmessage`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      to: to.charAt(0) !== '+' ? `+${to}` : to,
      message: message,
    }),
  })
  const data = await response.json()
  return data;
}
