import formatNumber from "services/formatNumber"
import { customerExperiencePhone } from "services/helpers"

const parseData = (order, client, slug) => {
    const data = {
        name: client.nombre,
        orderCode: order.code,
        date: `${order.created_at.slice(8, 10)}/${order.created_at.slice(5, 7)}/${order.created_at.slice(0, 4)}`,
        time: `${new Date(order.created_at).getHours() < 10 ? `0${new Date(order.created_at).getHours()}` : new Date(order.created_at).getHours()}:${new Date(order.created_at).getMinutes() < 10 ? `0${new Date(order.created_at).getMinutes()}` : new Date(order.created_at).getMinutes()}`,
        deliveryDate: `${order.selected_delivery_date.day}/${order.selected_delivery_date.month}`,
        shopName: order.shop_name,
        orderValue: order.sum_of_line_totals.value,
        clientPhone: client.telefono,
        total: order.total.value,
        paymentMethod: client.payment_method || '-',
        serviceCharge: Number(order?.service_charge_value.value) ?? null,
        couponValue: order.discount_value.value ?? null,
        managerLink: `manager.el-mercadito.co/${slug}/${order.id}?utm_souce=transact&utm_medium=wa&utm_campaign=order_confirm`,
        countryId: order.country_id
    }
    return data
}
export default async function order_confirmation_to_community_leader(order, datosComprador, dataShop, deliveryPoint) {
    const data = parseData(order, datosComprador, dataShop.slug)
    let message = ''
    const hasCoupon = order.coupon_id
    const hasServiceCharge = Number(order?.service_charge_value.value) > 0 ?? null
    if(!deliveryPoint) {
        if(hasCoupon && !hasServiceCharge) {
            switch(order.country_id) {
                case 1:
                    message = `Hola ${data.shopName}, ¡tenés un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nDescuento cupón: - $${data.couponValue}\n*Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 Ver detalle del pedido acá: ${data.managerLink}`
                    break
                case 2:
                    message = `Hola ${data.shopName}, ¡tienes un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nDescuento cupón: - $${data.couponValue}\n*Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 Ver detalle del pedido aquí: ${data.managerLink}`
                    break
                default:
                    message = `Hola ${data.shopName}, ¡tienes un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nDescuento cupón: - $${data.couponValue}\n*Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 Ver detalle del pedido aquí: ${data.managerLink}`
                    break
            } 
        } else if(!hasCoupon && hasServiceCharge) {
            switch(order.country_id) {
                case 1:
                    message = `Hola ${data.shopName}, ¡tenés un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nTasa de servicio: $${data.serviceCharge}\n*Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 Ver detalle del pedido acá: ${data.managerLink}`
                    break
                case 2:
                    message = `Hola ${data.shopName}, ¡tienes un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nTasa de servicio: $${data.serviceCharge}\n*Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 Ver detalle del pedido aquí: ${data.managerLink}`
                    break
                default:
                    message = `Hola ${data.shopName}, ¡tienes un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nTasa de servicio: $${data.serviceCharge}\n*Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n
                    📝 Ver detalle del pedido aquí: ${data.managerLink}`
                    break
            }
        } else if(hasCoupon && hasServiceCharge) {
            switch(order.country_id) {
                case 2:
                    message = `Hola ${data.shopName}, ¡tenés un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nDescuento cupón: - $${data.couponValue}\nTasa de servicio: $${data.serviceCharge}\n*Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 Ver detalle del pedido acá: ${data.managerLink}`
                    break
                case 1:
                    message = `Hola ${data.shopName}, ¡tienes un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nDescuento cupón: - $${data.couponValue}\nTasa de servicio: $${data.serviceCharge}\n*Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 Ver detalle del pedido aquí: ${data.managerLink}`
                    break
                default:
                    message = `Hola ${data.shopName}, ¡tienes un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nDescuento cupón: - $${data.couponValue}\nTasa de servicio: $${data.serviceCharge}\n*Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 Ver detalle del pedido aquí: ${data.managerLink}`
                    break
            }
        } else {
            switch(order.country_id) {
                case 1:
                    message = `Hola ${data.shopName}, ¡tenés un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 Ver detalle del pedido acá: ${data.managerLink}`
                case 2:
                    message = `Hola ${data.shopName}, ¡tienes un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 Ver detalle del pedido aquí: ${data.managerLink}`
                    break
                default:
                    message = `Hola ${data.shopName}, ¡tienes un nuevo pedido!\n\n🛒 El pedido es el número # *${data.orderCode}*\nRealizado el ${data.date} a las ${data.time}.\n\n📦 Fecha de entrega: *${data.deliveryDate}*\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n💲 *Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 Ver detalle del pedido aquí: ${data.managerLink}`
                    break
            } 
        }
    }
    if(deliveryPoint) {
        switch(order.country_id) {
            case 1:
                message = `📣 *RECIBISTE UN PEDIDO*\n\nHola ${deliveryPoint.name}, un cliente te eligió como su Coordinadora de Entregas 🥳\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n🛒 *Pedido*\n- ID #${data.orderCode}\n- Fecha de entrega: ${data.deliveryDate}\n- Detalle del pedido: ${data.managerLink}\n\n 💵 *Pago*\n- Total a pagar por cliente: $${formatNumber(data.total)}\n- Método de pago: ${data.paymentMethod}\n\n📱 Cerca de la fecha de entrega, recordá coordinar el horario de retiro con el cliente. \n\n🧑‍💻 Cualquier duda podés escribirle a nuestro Equipo de Soporte: wa.me/${customerExperiencePhone[String(data.countryId)]}`
                break
            case 2:
                message = `📣 *RECIBISTE UN PEDIDO*\n\nHola ${deliveryPoint.name}, un cliente te eligió como su Coordinadora de Entregas 🥳\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n🛒 *Pedido*\n- ID #${data.orderCode}\n- Fecha de entrega: ${data.deliveryDate}\n- Detalle del pedido: ${data.managerLink}\n\n 💵 *Pago*\n- Total a pagar por cliente: $${formatNumber(data.total)}\n- Método de pago: ${data.paymentMethod}\n\n 📱 Cerca de la fecha de entrega, recuerda coordinar el horario de retiro con el cliente\n\n🧑‍💻 Cualquier duda puedes escribirle a nuestro Equipo de Soporte: wa.me/${customerExperiencePhone[String(data.countryId)]}`
                break
            default:
                message = `📣 *RECIBISTE UN PEDIDO*\n\nHola ${deliveryPoint.name}, un cliente te eligió como su Coordinadora de Entregas 🥳\n\n👤 *Cliente*\nNombre: ${data.name}\nWhatsApp: wa.me/${data.clientPhone}\n\n🛒 *Pedido*\n- ID #${data.orderCode}\n- Fecha de entrega: ${data.deliveryDate}\n- Detalle del pedido: ${data.managerLink}\n\n 💵 *Pago*\n- Total a pagar por cliente: $${formatNumber(data.total)}\n- Método de pago: ${data.paymentMethod}\n\n 📱 Cerca de la fecha de entrega, recuerda coordinar el horario de retiro con el cliente\n\n🧑‍💻 Cualquier duda puedes escribirle a nuestro Equipo de Soporte: wa.me/${customerExperiencePhone[String(data.countryId)]}`
                break
        }     
    }
    return message
}

