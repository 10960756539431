export const arrCountriesById = (country) => {
    if(country === 1){
        return ['ar']
      }
      if(country === 2){
        return ['mx']
      }
      if(country === 180){
        return ['pe']
      }
      else{
        return ['mx']
      }
}

export const countryCode = (country) => {
    if(country === 1){
      return 'ar'
    }
    if(country === 2){
      return 'mx'
    }
    if(country === 180){
      return 'pe'
    }
    else{
      return 'mx'
    }
}

export const labelInputPhoneByCountry = (country) => {
    if(country === 1){
      return '+549 11 5555-5555'
    }
    if(country === 2){
      return '+52 1 5555-5555'
    }
    if(country === 180){
      return '+51 555 555 555'
    }
    else{
      return '+52 1 5555-5555'
    }
}

export const normalizePhone = (country, phone) => {
        if(country === 1) {
            const phoneLength = phone.length
            if(phone.substring(0,6) === '+54911') return phone
            if(['+54011','+01115','+54915','+01115'].includes(phone.substring(0,6))) return `+54911${phone.substring(6, phoneLength)}`
            if(['+5411','+5415'].includes(phone.substring(0,5))) return `+54911${phone.substring(5, phoneLength)}`
            if(['+5411','+5415'].includes(phone.substring(0,5))) return +`54911${phone.substring(5, phoneLength)}`
            if(['+011'].includes(phone.substring(0,4))) return `+54911${phone.substring(4, phoneLength)}`
            if(['+11'].includes(phone.substring(0,3))) return `+54911${phone.substring(3, phoneLength)}`
            if(phoneLength === 10 && ['+54'].includes(phone.substring(0,3))) return `+54911${phone.substring(3, phoneLength)}`
            return phone
          }
          return phone
    }

