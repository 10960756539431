export const getDefaultTier = async (shopId, countryId) => {
    const url = `/api/tiers/default/${shopId}?countryId=${countryId}`
    try {
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        return data.data;
    } catch (error) {
        return { error };
    }
}

export const getTier = async (tierId) => {
    const url = `/api/tiers/id/${tierId}`
    try {
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        return data.data;
    } catch (error) {
        return { error };
    }
}

export const isShopInTier = async (shopId, tierId) => {
    const tier = await getTier(tierId);
    return tier.shops.includes(shopId);
}