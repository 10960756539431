import { localNames } from "./helpers"
import { getLocalStorage } from "./localstorage"
import { finalPrice } from "./producthelpers"

export const actualTotal = (id, lines) => {
  return parseFloat(lines.find(line => line.product_id === id).total.value)
}

export const cartLine = (product, category, quantity, type) => {
    const object = {
        nombre: product.name,
        categoria: category,
        imagen: product["master-product"].image,
        productid: product.id,
        precio:
          product.price *
          (1 -
            (product["product-discount"]
              ? product["product-discount"].discount_percentage / 100
              : 0)),
        cantidad: quantity,
        subtotal: (Math.round(quantity * product.price * 100) / 100) *
        (1 -
          (product["product-discount"]
            ? product["product-discount"].discount_percentage / 100
            : 0)),
        medida: product["master-product"].erp_purchase_measure_unit_id,
        escala: product.scale,
        datos_producto: product,
        notes: product.notes,
      }
    if(type === 'substract') {
        object = {
            ...object,
            cantidad: object.cantidad * -1,
            subtotal: object.subtotal * -1
        }
    }
    return object
}

export const itemsQuantity = () => {
  const cart = getLocalStorage(localNames.cart)
  let quantity = 0
  cart.forEach(element => {
    quantity = quantity + element.cantidad
  });
  return quantity
}

export const updateCart = async (actualLines, actualProducts) => {
  return actualLines.map((line) => {
    const product = actualProducts?.filter((product) => product.id === line.productid)[0]
    const maxUnit = product?.max_units_per_delivery > 0 ? product?.max_units_per_delivery : (product?.max_units_per_order > 0 ? product?.max_units_per_order: 0);
    return {
      ...line,
      datos_producto: product,
      precio: finalPrice(product),
      subtotal: Math.round((finalPrice(product) * (maxUnit === 0 ? line.cantidad : (line.cantidad > maxUnit ? maxUnit : line.cantidad))) * 100) / 100,
      cantidad: maxUnit === 0 ? line.cantidad : (line.cantidad > maxUnit ? maxUnit : line.cantidad),
    }
  });
}

export const updateQuantitiesInCart = (actualLines, errors) => {
  return actualLines.map((line) => ({
    ...line,
    cantidad: errors.find((error) => error.product_id === line.productid) ? errors.find((error) => error.product_id === line.productid).limit - errors.find((error) => error.product_id === line.productid).existing_quantity : line.cantidad,
    subtotal: errors.find((error) => error.product_id === line.productid) ? Math.round(((errors.find((error) => error.product_id === line.productid).limit - errors.find((error) => error.product_id === line.productid).existing_quantity) * line.precio) * 100) / 100 : line.subtotal,
  }));
}
