import { localNames } from "./helpers";

export const setLocalStorage = (name, value) => {
    localStorage.setItem(name, JSON.stringify(value));
    return true;
};

export const removeItem = (name) => {
    localStorage.removeItem(name);
    return true;
};

export const getLocalStorage = (name) => {
    if(typeof window !== 'undefined') {
        const value = localStorage.getItem(name);
        if (value) {
          return JSON.parse(value);
        }
        return null;
      }
};

export const getStringLocalStorage = (name) => {
    if(typeof window !== 'undefined') {
        const value = localStorage.getItem(name);
        if (value) {
          return value;
        }
        return null;
      }
};

export const addLocalStorage = (name, value) => {
    localStorage.setItem(name, value)
}


export const addRecentSearch  = async (value, lengthLastSearches) => {
    const recentSearches = getLocalStorage(localNames.recent_searches) ? getLocalStorage(localNames.recent_searches) : [];
        if(!recentSearches.includes(value)) {
            if(recentSearches.length < lengthLastSearches) {
                recentSearches.unshift(value);
            } else {
                recentSearches.unshift(value);
                recentSearches.splice(lengthLastSearches, recentSearches.length - lengthLastSearches);
            }
        } else {
            const index = recentSearches.findIndex(element => element === value);
            recentSearches.splice(index, 1);
            recentSearches.unshift(value);
        }
        setLocalStorage(localNames.recent_searches, recentSearches);
    return recentSearches;
};

export const deleteRecentSearch = async (value) => {
    const recentSearches = getLocalStorage(localNames.recent_searches) ? getLocalStorage(localNames.recent_searches) : [];
    let index = recentSearches.indexOf(value);
    if (index > -1) {
        recentSearches.splice(index, 1);
        setLocalStorage(localNames.recent_searches, recentSearches);
    }
    if(recentSearches.length < 1) {
        removeItem(localNames.recent_searches);
      };
    return recentSearches;
};

export const deleteAllRecentSearches = () => {
    removeItem(localNames.recent_searches);
    return true;
};

export const resetCart = () => {
    setLocalStorage(localNames.cart, []);
    setLocalStorage(localNames.total, 0);
    setLocalStorage(localNames.quantity, 0);
    setLocalStorage(localNames.detail, '');
    return
}