export const setCookie = async (name, value, exp) => {
    const url = exp > 0 ? `/api/cookies?cookieName=${name}&cookieValue=${value}&exp=${exp}` : `/api/cookies?cookieName=${name}&cookieValue=${value}`
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const data = await response.json()
    return data
}

export const getCookie = async (name) => {
    const url = `/api/cookies?cookieName=${name}`
    const response = await fetch(url)
    const data = await response.json()
    return data
}

export const deleteUserCookie = async (name) => {
    const url = `/api/cookies?cookieName=${name}`
    const response = await fetch(url, {
        method: 'DELETE'
    })
    const data = await response.json()
    return data
}