export default function getMinimumOrderValue(products_with_min_order_value, cart) {
    if(products_with_min_order_value === 'disabled') return 0;
    
    let minimum = 0;

    for (const line of cart) {
        const minValue = line?.datos_producto?.minimum_order_value * line.cantidad;
        if (minValue > 0) {
            minimum += minValue;
        }
    }
    return minimum;
}