/* eslint-disable camelcase */
export default async function getpaymentlink(
    payment,
    slug,
    payment_method,
    community_leader,
    order,
  ) {
    const response = await fetch(
      `/api/paymentlink/${payment_method}`,
      {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          payment,
          slug,
          community_leader,
          order,
        }),
      },
    );
    const data = await response.json();
    return data;
  }
