import isLive from "./islive";

export const localNames = {
    recent_searches: 'm_recent_searches',
    cart: 'carrito',
    phone: 'telcomprador',
    token: 'nilus_session_token',
    total: 'total',
    quantity: 'cantidadproductos',
    customer: 'customer',
    detail: 'detalle',
    anonymousId: 'anonymousId',
    customer_id: 'customer_id',
    close_banner: 'close_banner',
}

export const currencies = {
1: '$',
2: '$',
180: 'S/',
46: '$',
59: '$'
};

export const activeCountries = [
    'pe',
    'co',
    'ec'
]
export const countriesInSelector = [
    'ar',
    'mx',
]

export const countryCode = {
1: 'ar',
2: 'mx',
180: 'pe',
46: 'co',
59: 'ec'
}

export const countryCodeNumber = {
1: '+54',
2: '+52',
180: '+51',
46: '+57',
59: '+593'
}

export const localeCodeByCountryCode = {
ar: 'es-AR',
mx: 'es-MX',
pe: 'es-PE',
co: 'es-CO',
ec: 'es-CO'
}

export const countrySelection = {
    ar: {
        name: 'Argentina',
        flag: '/imgs/flagAr.svg',
    },
    mx: {
        name: 'México',
        flag: '/imgs/flagMx.svg',
    }
}


export const locationByCountryCode = {
ar: { lat: -34.6156548, lng: -58.3815591, zoom: 15},
mx: { lat: 19.3909829, lng: -99.3087678, zoom: 15},
pe: { lat: -9.189967, lng: -75.015152, zoom: 15}
}

export const localeCode = {
1: 'es-AR',
2: 'es-MX',
180: 'es-PE',
46: 'es-CO',
59: 'es-CO',
demo: 'en-US'
}

export const capitalizeFirstLetter = (string) => {
    if(string) {
        return string.charAt(0).toUpperCase() + string?.slice(1);
    }
}


export const maintenanceMode = {
    1: false,
    2: false,
    180: false,
    46: false,
    59: false
}

export const customerExperiencePhone = {
    1: '+5491165897556',
    2: '+525572250549',
}

export const distanceShopsToUser = (distance, country) => {
    let distance_type;
    
    if(localStorage.getItem('distance_type')){  
        distance_type = localStorage.getItem('distance_type');
    } else {
        const distanceTypes = {
            0: 'distance',
            1: 'time'
        };
        let num = Math.round(Math.random());
        distance_type = distanceTypes[num];
        localStorage.setItem('distance_type', distance_type);
    }
    
    const walkingSpeed = 5;
    if(distance_type == 'distance'){
        const newDistance = Math.round(distance / 100);
        if(country == 1){
            return `${newDistance} ${newDistance <= 1 ? 'cuadra' :'cuadras'}`
        }else if(country == 2){
            return `${newDistance} ${newDistance <= 1 ? 'calle' :'calles'}`
        }else{
            return `${newDistance} ${newDistance <= 1 ? 'cuadra' :'cuadras'}`
        }
    }
    if(distance_type == 'time'){
        return `${Math.round(distance / 1000 / walkingSpeed * 60)} min`
    }
    return '';
};

export const haversineDistance = (coord1, coord2) => {
    const toRadians = degrees => degrees * Math.PI / 180;
    const R = 6371;

    const dLat = toRadians(coord2.lat - coord1.Latitude);
    const dLon = toRadians(coord2.lng - coord1.Longitude);
    const lat1 = toRadians(coord1.Latitude);
    const lat2 = toRadians(coord2.lat);

    const a = Math.pow(Math.sin(dLat / 2), 2) +
              Math.cos(lat1) * Math.cos(lat2) *
              Math.pow(Math.sin(dLon / 2), 2);
    const distance = 2 * R * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return distance * 1000;
}

export const getIsLive = async () => {
    const res = await isLive();
    return res;
}

export const supportConfigByCountry = {
    1: {
        phone: '+5491165897556',
        chatBotId: {
            order: '/start wf101753w57365',
            checkout: '/start -uflow::f101753s1076899',
            general: '/start -uflow::f101753s1076901',
        },
    },
    2: {
        phone: '+525572250549',
        chatBotId: {
            order: '/start wf104695w57363',
            checkout: '/start -uflow::f104695s1076903',
            general: '/start -uflow::f104695s1076905',
        },
    },
    59: {
        phone: '+51949714525',
        chatBotId: {
            order: '',
            checkout: '',
            general: '',
        },
    },
};

export const generateSupportLink = (countryId, type, extraParams = '') => {
    const countryConfig = supportConfigByCountry[countryId];
    if (!countryConfig) {
        return `No support configuration available for country ID: ${countryId}`;
    }

    const phone = countryConfig.phone;
    const chatBotId = countryConfig.chatBotId[type];

    if (!phone || !chatBotId) {
        return `Support information is incomplete for country ID: ${countryId}, type: ${type}`;
    }

    return `https://wa.me/${phone}?text=${chatBotId}${extraParams}`;
};

export const phoneSupportByCountry = {
    1: '+5491165897556',
    2: '+525572250549',
    59: '+51949714525',
};

export const tierAdviceText = (countryId) => {
    switch (countryId) {
        case 1:
            return 'Oh, parece que estabas viendo el catálogo con promociones exclusivas para primera compra. Hemos actualizado algunos precios, recuerda mantenerte atento a promociones exclusivas para nuestros clientes leales.'
            break;

        case 2:
            return 'Oh, parece que estabas viendo el catálogo con promociones exclusivas para primera compra. Hemos actualizado algunos precios, recuerda mantenerte atento a promociones exclusivas para nuestros clientes leales.';
            break;
        
        default:
            return 'Oh, parece que estabas viendo el catálogo con promociones exclusivas para primera compra. Hemos actualizado algunos precios, recuerda mantenerte atento a promociones exclusivas para nuestros clientes leales.';
            break;
    }
}

export const fliptFeatureFlags = ['menues','whatsapp_button', 'delivery_code', 'unit_price_in_cart', 'guarantee_cashback', 'suggested_products', 'add_discount_coupon','modify_personal_data', 'repeat_last_order_button', 'contact_support_widget'];

//default logo in header data
export const DEFAULT_THEME_IMAGE_DATA = {
    logo: "/imgs/newLogoM.svg",
    logoText: "/imgs/mercaditoText.svg",
    alt: "Mercadito",
  };

//👆Set up the country logos if you want them to be different from the default.👆
export const imagesByCountryId = {
    59: {
      logo: "/imgs/wfp/wfp-logo-emblem-white.svg",
      logoText: "/imgs/wfp/wfp-letters-extended-white-es.svg",
      alt: "Programa Mundial de Alimentos",
    },
  };

//default size of the logo in header data
export const DEFAULT_THEME_SIZE_DATA = {
    width: 110,
    height: 14,
  };

//👆Set up the image logo sizes if you want them to be different from the default.👆
export const imageSizeByCountryId = {
    59: {
      width: 218,
      height: 14,
    },
  };