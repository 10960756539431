export default async function setorder(order, autoconfirm) {
    const url = autoconfirm ? `/api/order?autoconfirm=true` : `/api/order`
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(order)
    })
    const data = await response.json()
    return ({...data, status: response.status})
}