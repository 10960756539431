function day_name(name) {
  if(name === 'monday') return('lunes')
  if(name === 'tuesday') return('martes')
  if(name === 'wednesday') return('miércoles')
  if(name === 'thursday') return('jueves')
  if(name === 'friday') return('viernes')
  if(name === 'saturday') return('sábado')
  if(name === 'sunday') return('domingo')
} 

function month_number(name) {
  if(name === 'january') return(1)
  if(name === 'february') return(2)
  if(name === 'march') return(3)
  if(name === 'april') return(4)
  if(name === 'may') return(5)
  if(name === 'june') return(6)
  if(name === 'july') return(7)
  if(name === 'august') return(8)
  if(name === 'september') return(9)
  if(name === 'october') return(10)
  if(name === 'november') return(11)
  if(name === 'december') return(12)  
} 

export async function getDeliveryDates(id) {
  const response = await fetch(`/api/deliverydates/${id}`)
  const data = await response.json()
  let dates = data?.data?.next_delivery_dates ?? []
  let unique_dates = []
  dates?.map(date => {
    if(!unique_dates.includes(date)) {
      unique_dates.push(date)
    }
  })
  return unique_dates
}
export default async function getproximafechaentrega(id) {
  const response = await fetch(`/api/deliverydates/${id}`)
  const data = await response.json()
  let fecha =  data?.data?.next_delivery_dates[0].split(' ')
  if(!fecha) return ({text: 'sin fecha de entrega'})
  return ({date: fecha[0].toLowerCase(), year: parseInt(fecha[3]), month: month_number(fecha[1].toLowerCase()), day: parseInt(fecha[2])})
}