import React, { useEffect, useState } from 'react';
import getdatostienda from 'services/getdatostienda';
import getproximafechaentrega from 'services/getproximafechaentrega';
import getCountry from 'services/getCountry';
import sendmessage from 'services/sendmessage';
import getcategories from 'services/getcategories'
import setorder from 'services/setorder';
import order_confirmation_to_client from 'services/mensajeswhatsapp/order_confirmation_to_client';
import getcommunityleader from 'services/getcommunityleader';
import order_confirmation_to_community_leader from 'services/mensajeswhatsapp/order_confirmation_to_community_leader';
import { useRouter } from 'next/router';
import isLocal from 'services/islocal';
import getUser from 'services/getuser';
import getBanners from 'services/getBanners';
import { trackOrderNotSent } from 'componentes/CheckoutWithWallet/tracking';
import getCustomer from 'services/getcustomer';
import { isCatalog } from 'services/featureflags';
import setOrderObject from 'services/setorderobject';
import { getLocalStorage, setLocalStorage } from 'services/localstorage';
import { countriesInSelector, countryCode, localNames, distanceShopsToUser } from 'services/helpers';
import { trackUser } from './tracking';
import { getWalletBalance, getWalletMovements } from 'services/wallet';
import { track } from 'services/gtm';
import getMinimumOrderValue from 'services/minimumordervalue';
import { getMessageToClient } from 'services/mensajeswhatsapp/order_confirmation_to_client_v2';
import getbrands from 'services/getbrands';
import { getDefaultTier, isShopInTier } from 'services/tiers';
import getpaymentlink from 'services/getpaymentlink';
import { deleteUserCookie, getCookie, setCookie } from 'services/cookies';
import { getProfilePicture, userHasOrders } from 'services/user';
import getCurrentLocation from "services/getcurrentlocation";
import getPickupPoint from 'services/getpickuppoint';
import getFeatureFlags from 'services/flipt';

let Context = React.createContext();

let { Provider, Consumer } = Context;

// const hoy = new Date(Date.UTC(hoy1.getFullYear(), hoy1.getMonth(), hoy1.getDate(), hoy1.getHours(), hoy1.getMinut  es(), 0))

function CarritoProvider({ children }) {
  const [sucursal, setSucursal] = useState(null);
  const [pais, setPais] = useState(null);
  const [currentInfoCountry, setCurrentInfoCountry] = useState(null);
  const [dataShop, setDataShop] = useState(null);
  const [communityLeader, setCommunityLeader] = useState(null)
  const [categories, setCategories] = useState(null)
  const [banners, setBanners] = useState(null);
  const [bannerSlider, setBannerSlider] = useState(null);
  const [bannerHero, setBannerHero] = useState(null);
  const [bannerInApp, setBannerInApp] = useState(null)
  const [fechaEntrega, setFechaEntrega] = useState(null);
  const [productos, setProductos] = useState(null);
  const [cargandoCarrito, setCargandoCarrito] = useState(true);
  const [cantidad, setCantidad] = useState(0);
  const [total, setTotal] = useState(0);
  const [ultimoPedido, setUltimoPedido] = useState(null);
  const [estadoBarra, setEstadoBarra] = useState('inicial');
  const [actualizar, setActualizar] = useState(false);
  const [carrito, setCarrito] = useState([]);
  const [productosEnCategoria, setProductosEnCategoria] = useState(null);
  const [datosComprador, setDatosComprador] = useState({
    nombre: '',
    apellido: '',
    telefono: '',
    comentario: '',
    payment_method: '',
  });
  const [step, setStep] = useState(null)
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [lines, setLines] = useState([])
  const [reFetchNextDeliveryDate, setReFetchNextDeliveryDate] = useState(null)
  const [isSG, setIsSG] = useState(null)
  const [isCatalogSG, setIsCatalogSG] = useState(null)
  const [user, setUser] = useState(null)
  const [couponValidated, setCouponValidated] = useState(null)
  const [valueForMinimumPurchase, setValueForMinimumPurchase] = useState(null)
  const [couponErrorCode, setCouponErrorCode] = useState(null)
  const [couponErrorAtCheckout, setCouponErrorAtCheckout] = useState(false)
  const [customer, setCustomer] = useState(null)
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isSnackBarShown, setIsSnackBarShown] = useState(false);
  const [deleteProducts, setDeleteProducts] = useState([]);
  const [promotionsCarouselId, setPromotionsCarouselId] = useState(null);
  const [productsHighlights, setProductsHighlights] = useState(null);
  const [productsHighlightsTitle, setProductsHighlightsTitle] = useState('');
  const [refreshUser, setRefreshUser] = useState(false)
  const [walletAmount, setWalletAmount] = useState(null);
  const [walletTransactions, setWalletTransactions] = useState(null);
  const [refetchWallet, setRefetchWallet] = useState(false);
  const [isShowBanner, setIsShowBanner] = useState(null);
  const [brands, setBrands] = useState(null);
  const [productsByBrand, setProductsByBrand] = useState(null);
  const [deliveryPointSelector, setDeliveryPointSelector] = useState(null);
  // const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState(() => {
  //   const savedValue = localStorage ? localStorage.getItem('selectedDeliveryPoint') : null
  //   return savedValue ? JSON.parse(savedValue) : null;
  // });
  const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState(null);
  const [modalDeliveryPointSelector, setModalDeliveryPointSelector] = useState(false)
  const [modalDateSelector, setModalDateSelector] = useState(false);
  const [userTier, setUserTier] = useState('not_set')
  const [profilePicture, setProfilePicture] = useState(null);
  const [nearestShops, setNearestShops] = useState(null);
  const [actualLocation, setActualLocation] = useState(null);
  const [sharedLocation, setSharedLocation] = useState(null);
  const [isDefaultTier, setIsDefaultTier] = useState(null);
  const [showTierAdvice, setShowTierAdvice] = useState(false);
  const [featureFlags, setFeatureFlags] = useState(null);

  const router = useRouter()
  const initialMinimumOrderValue = localStorage.getItem('minimumOrderValue');

  const [minimumOrderValue, setMinimumOrderValue] = useState(initialMinimumOrderValue || null);
  //activate customer io

    useEffect(()=> {
      if(pais){
        getFeatureFlags(pais).then(setFeatureFlags);
      }
    },[pais]);

  //Get user current location 
  useEffect(()=> {
    localStorage.removeItem('DataLayerHistory')
    getCurrentLocation()
    .then((currentLocation) => {
        setActualLocation({...currentLocation, zoom: 15})
        setSharedLocation(true);
    },(error) => {
      setSharedLocation(false);
    })
  },[]);

  
useEffect(() => {
  if ((actualLocation || selectedDeliveryPoint) && pais) {
    const location = actualLocation ?? selectedDeliveryPoint?.ShopLocation ?? selectedDeliveryPoint?.shop_location;
    const latitude = location?.lat ?? location?.Latitude;
    const longitude = location?.lng ?? location?.Longitude;

    if (latitude && longitude) {
      getPickupPoint(pais, latitude, longitude, 10000).then((res) => {
        setNearestShops(res);
      });
    }
  }
}, [actualLocation, pais]);


  useEffect(() => {
    track('activate_customerio', { event: 'activate_customerio' })
  }
  ,[])

  //correct cart when quantity is <= 0
  useEffect(() => {
    if(carrito.length > 0) {
      setCantidad(carrito.reduce((acc, line) => acc + line.cantidad, 0))
      setTotal(Math.round((carrito.reduce((acc, line) => acc + line.subtotal, 0)) * 100) / 100)
      carrito.forEach(line => {
        if(line.cantidad <= 0) {
          handleBorrar(line.productid, line.cantidad, line.subtotal, null)
        }
      })
    } else {
      setCantidad(0)
      setTotal(0)
    }
  }, [carrito])

  //user logueado

  useEffect(() => {
    user && window &&
    getCustomer(user.customer_id)
    .then(res => {
      setCustomer(res.data)
      localStorage.setItem('nombrecomprador', res.data.customer.name)
      localStorage.setItem('apellidocomprador', res.data.customer.last_name)
      localStorage.setItem('telcomprador', res.data.customer.phone)
      localStorage.setItem('customer_id', user.customer_id)
      setRefreshUser(false)
    })
  }, [user, refreshUser])

  useEffect(() => {
    if(user) {
      const userId = user.customer_id;
      getProfilePicture(userId).then(setProfilePicture);
    }
  }, [user]);

  useEffect(() => {
    if(dataShop) {
      if(customer) {
        const customerId = customer.customer.id
        const userTier = customer.customer?.tier_id ?? null
        if(userTier) {
          deleteUserCookie('nilus_tier_id')
        }
        getCookie('nilus_last_user_tier')
        .then((lastTier) => {
          if(lastTier.value === 'undefined') {
            deleteUserCookie('nilus_last_user_tier')
            userHasOrders(customerId)
            .then((hasOrders) => {
              if(hasOrders) {
                if(userTier) {
                  setUserTier(userTier)
                  if(isDefaultTier) {
                    setIsDefaultTier(false)
                    setShowTierAdvice(true)
                    setTimeout(() => setShowTierAdvice(false), 10000)
                    if(carrito.length > 0) {
                      router.push(`/${sucursal}/mi-carrito`)
                    }
                  }
                  setCookie('nilus_last_user_tier', userTier)
                } else {
                  getDefaultTier(dataShop.id, dataShop.country_id)
                  .then(res => {
                    if(res.length > 0) {
                      const tierId = res[0]?.id
                      setUserTier(tierId)
                      setIsDefaultTier(true)
                    } else {
                      setUserTier(null)
                      setIsDefaultTier(false)
                    }
                  })
                }
              } else {
                getDefaultTier(dataShop.id, dataShop.country_id)
                .then(res => {
                  if(res.length > 0) {
                    const tierId = res[0]?.id
                    setUserTier(tierId)
                    setIsDefaultTier(true)
                  } else {
                    setUserTier(null)
                    setIsDefaultTier(false)
                  }
                })
              }
            })
          }
          if(lastTier.value && lastTier.value !== 'no_tier' && lastTier.value !== 'undefined' && lastTier.value !== 'null') {
            if(userTier) {
              setUserTier(userTier)
              setIsDefaultTier(false)
              setCookie('nilus_last_user_tier', userTier)
            } else {
              setUserTier(lastTier.value)
              setIsDefaultTier(false)
            }
          } else {
            userHasOrders(customerId)
                .then((hasOrders) => {
                  if(hasOrders) {
                    if(userTier) {
                      setUserTier(userTier)
                      if(isDefaultTier) {
                        setIsDefaultTier(false)
                        setShowTierAdvice(true)
                        setTimeout(() => setShowTierAdvice(false), 10000)
                        if(carrito.length > 0) {
                          router.push(`/${sucursal}/mi-carrito`)
                        }
                      }
                      setCookie('nilus_last_user_tier', customer.customer.tier_id)
                    } else {
                      getDefaultTier(dataShop.id, dataShop.country_id)
                      .then(res => {
                        if(res.length > 0) {
                          const tierId = res[0]?.id
                          setUserTier(tierId)
                          setIsDefaultTier(true)
                        } else {
                          setUserTier(null)
                          setIsDefaultTier(false)
                        }
                      })
                    }
                  } else {
                    getDefaultTier(dataShop.id, dataShop.country_id)
                    .then(res => {
                      if(res.length > 0) {
                        const tierId = res[0]?.id
                        setUserTier(tierId)
                        setIsDefaultTier(true)
                      } else {
                        setUserTier(null)
                        setIsDefaultTier(false)
                      }
                    })
                  }
                })
          }
        })
      } else {
        getCookie('nilus_last_user_tier')
        .then(res => {
          if(res.value && res.value !== 'no_tier' && res.value !== 'undefined') {
            setUserTier(res.value)
            setIsDefaultTier(false)
          } else {
            getDefaultTier(dataShop.id, dataShop.country_id)
            .then(res => {
              deleteUserCookie('nilus_last_user_tier')
              if(res.length > 0) {
                const tierId = res[0]?.id
                setUserTier(tierId)
                setIsDefaultTier(true)
              } else {
                setUserTier(null)
                setIsDefaultTier(true)
              }
            })
          }
        })
      }
    }
  }, [customer, dataShop])


  //Define user tier
//  useEffect(() => {
//     if(featureTierOn && dataShop) {
//       getCookie('nilus_last_user_tier').then(res => {
//         if((res.value)) {
//           if(res.value !== 'no_tier') {
//             setUserTier(res.value)
//           } else {
//             setUserTier(null)
//           }
//         } else {
//           getCookie('nilus_tier_id').then(res => {
//             if(res.value && res.value !== 'no_tier_default') {
//               getDefaultTier(dataShop.id).then(async res => {
//                 if(res) {
//                   const tierId = res[0]?.id ?? null
//                   if(tierId && tierId !== res.value) {
//                     isShopInTier(dataShop.id, tierId).then(res => {
//                       if(res) {
//                         setUserTier(tierId)
//                         setCookie('nilus_tier_id', tierId)
//                       }
//                         else setUserTier(null)
//                     })
//                   } else if (tierId === res.value) {
//                     setUserTier(tierId)
//                   } else {
//                     setUserTier(null)
//                     setCookie('nilus_tier_id', 'no_tier_default')
//                   }
//                 }
//               })
//             } else if(res.value === 'no_tier_default') {
//               getDefaultTier(dataShop.id).then(async res => {
//                 if(res) {
//                   if(user !== null && !user && dataShop) {
//                     const tierId = res[0]?.id ?? null
//                     if(tierId) {
//                       isShopInTier(dataShop.id, tierId).then(res => {
//                         if(res) {
//                           setUserTier(tierId)
//                           setCookie('nilus_tier_id', tierId)
//                         }
//                           else setUserTier(null)
//                       })
//                     } else {
//                       setUserTier(tierId)
//                       setCookie('nilus_tier_id', 'no_tier_default')
//                     }
//                   }
//                   if(user && customer) {
//                     const customerId = customer.customer.id
//                     getOrders(customerId, '', 10).then(userOrders => {
//                       if(userOrders.data.length === 0) {
//                         getDefaultTier(dataShop.id).then(res => {
//                           if(res) {
//                             const tierId = res[0]?.id ?? null
//                             if(tierId) {
//                               isShopInTier(dataShop.id, tierId).then(res => {
//                                 if(res) setUserTier(tierId)
//                                   else setUserTier(null)
//                               })
//                             } else {
//                               setUserTier(tierId)
//                             }
//                           }
//                         })
//                       } else {
//                         const tierId = customer?.customer?.tier_id ?? null
//                         setUserTier(tierId)
//                       }
//                     })
//                   }
//                 } else {
//                   setUserTier(null)
//                 }
//               })
//             } else {
//               if(user !== null && !user && dataShop) {
//                 getDefaultTier(dataShop.id).then(async res => {
//                   if(res) {
//                     const tierId = res[0]?.id ?? null
//                     if(tierId) {
//                       isShopInTier(dataShop.id, tierId).then(res => {
//                         if(res) {
//                           setUserTier(tierId)
//                           setCookie('nilus_tier_id', tierId)
//                         }
//                           else setUserTier(null)
//                       })
//                     } else {
//                       setUserTier(tierId)
//                       setCookie('nilus_tier_id', 'no_tier_default')
//                     }
//                   }
//                 })
//               }
//               if(user && customer && dataShop) {
//                 const customerId = customer.customer.id
//                 getOrders(customerId, '', 10).then(userOrders => {
//                   if(userOrders.data.length === 0) {
//                     getDefaultTier(dataShop.id).then(res => {
//                       if(res) {
//                         const tierId = res[0]?.id ?? null
//                         if(tierId) {
//                           isShopInTier(dataShop.id, tierId).then(res => {
//                             if(res) setUserTier(tierId)
//                               else setUserTier(null)
//                           })
//                         } else {
//                           setUserTier(tierId)
//                         }
//                       }
//                     })
//                   } else {
//                     const tierId = customer?.customer?.tier_id ?? null
//                     setUserTier(tierId)
//                   }
//                 })
//               } else {
//                 setUserTier(null)
//               }
//             }
//           })
//         }
//       })
//     }
//  }, [user, customer, dataShop, featureTierOn])
  useEffect(() => {
    if(user && customer) trackUser(user.customer_id, customer.customer.phone)
    if(!user && !customer && datosComprador.telefono !== '') trackUser(null, datosComprador.telefono)
  }, [user, customer, datosComprador.telefono])
  // Obtener ultimo pedido en caso exista
  useEffect(() => {
    if(sucursal !== null && window) {
      if(localStorage.getItem('shop') !== sucursal) {
        localStorage.removeItem('shop')
        localStorage.setItem('carrito', JSON.stringify([]))
        localStorage.setItem('total', 0);
        localStorage.setItem('detalle', '');
        localStorage.removeItem('selectedDeliveryPoint')
        localStorage.setItem('cantidadproductos', 0);
        setCarrito([])
        setCantidad(0)
        setTotal(0)
        setActualizar(true)
        setSelectedDeliveryPoint(null)
        getUser()
        .then((res) => {
          if (!res.error) {
            setUser(res);
          } else {
            setUser(false);
          }
        });
        setUltimoPedido(null)
      } else {
        localStorage.getItem('ultimopedido') !== null &&
          setUltimoPedido(JSON.parse(localStorage.getItem('ultimopedido')))
          getUser()
          .then((res) => {
            if (!res.error) { 
              setUser(res);
            } else {
              setUser(false);
            }
          });
      }
    }
  }, [sucursal]);

  // Setear Datos Iniciales Comprador


  useEffect(() => {
    setDatosComprador({
      ...datosComprador,
      nombre: localStorage.getItem('nombrecomprador') !== null ? localStorage.getItem('nombrecomprador') : '',
      apellido: localStorage.getItem('apellidocomprador') !== null ? localStorage.getItem('apellidocomprador') : '',
      telefono: localStorage.getItem('telcomprador') !== null ? localStorage.getItem('telcomprador') : ''
    });
  }, [user]);

  useEffect(()=> {
    if(dataShop && window){
      const newMinimumOrderValue = getMinimumOrderValue(dataShop.products_with_min_order_value, carrito);
      setMinimumOrderValue(newMinimumOrderValue);
      localStorage.setItem('minimumOrderValue', newMinimumOrderValue);
    }
  },[carrito, dataShop]);

  // Carga de Datos comprador al session Storage

  useEffect(() => {
    window && datosComprador.nombre !== '' &&
      localStorage.setItem('nombrecomprador', datosComprador.nombre);
    datosComprador.telefono !== '' &&
      localStorage.setItem('telcomprador', datosComprador.telefono);
    datosComprador.apellido !== '' &&
      localStorage.setItem('apellidocomprador', datosComprador.apellido);
  }, [datosComprador.nombre, datosComprador.apellido, datosComprador.telefono]);

  useEffect(() => {
    window && datosComprador.telefono !== '' &&
      localStorage.setItem('telcomprador', datosComprador.telefono);
  }, [datosComprador.telefono]);

  // obtener datos de la Sucursal

  useEffect(() => {
    sucursal !== null &&
      getdatostienda(sucursal)
      .then(async res => {
        const countryId = res?.data?.country_id
        const countrySlug = countryCode[countryId]
        if(res?.data?.act_as_delivery_point) router.push(`/${countrySlug}`)
        if(!res.error) {
          setDataShop(res.data)
          res.data.customer_type_id === 3 ? setIsSG(true) : setIsSG(false)
        } else {
          router.push('/404')
        }
      })
    sucursal !== null && localStorage.setItem('shop', sucursal)
  }, [sucursal]);

  //Evaluar si es Catalogo de acuerdo a ser SG y el país

  useEffect(() => {
    if(isSG && pais) {
      setIsCatalogSG(isCatalog(isSG, pais))
    }
  }, [isSG, pais])

  // Obtener datode la lider comunitaria
  useEffect(() => {
    dataShop !== null && !isCatalogSG &&
    getcommunityleader(dataShop.community_leader_id)
    .then(setCommunityLeader)
  }, [dataShop, isSG])

  useEffect(() => {
    sucursal &&
    setDeliveryPointSelector(countriesInSelector.includes(sucursal));
  }, [sucursal])

  useEffect(() => {
    if (selectedDeliveryPoint !== null) {
      localStorage.setItem('selectedDeliveryPoint', JSON.stringify(selectedDeliveryPoint));
    }
  }, [selectedDeliveryPoint]);
  

  // Obtener Categorias

  useEffect(() => {
    dataShop !== null && isSG !== null &&
    getcategories(dataShop?.catalog_id)
    .then(setCategories)
  }, [dataShop, isSG])

  // Obtener Banners

  useEffect(() => {
    dataShop !== null && isSG !== null &&
    getBanners(dataShop.catalog_id)
    .then(setBanners)
  }, [dataShop, isSG])

  useEffect(() => {
    if (banners !== null) {
      const bannersType = banners.reduce((acc, banner) => {
        const { type } = banner.banner;
        acc[type] = [...(acc[type] || []), banner];
        return acc;
      }, {});
  
      setBannerSlider(bannersType[1] || []);
      setBannerHero(bannersType[2] || []);
      setBannerInApp(bannersType[3] || []);
    }
  }, [dataShop, banners]);
  
  useEffect(() => {
    if(!isCatalogSG) {
      dataShop !== null && (reFetchNextDeliveryDate === null || reFetchNextDeliveryDate) &&
      getproximafechaentrega(dataShop?.id)
      .then(res => {
        setFechaEntrega(res)
        setReFetchNextDeliveryDate(false)
      })
    } else {
      setFechaEntrega([])
    }
  }, [dataShop, reFetchNextDeliveryDate, isCatalogSG])


  // setear Pais 

  useEffect(() => {
    dataShop !== null && setPais(dataShop.country_id);
  }, [dataShop]);

  //Setear informacion del pais actual

  useEffect(() => {
    dataShop !== null &&
    getCountry(dataShop.country_id)
    .then(setCurrentInfoCountry)
  }, [dataShop])



  useEffect(() => {
    if(dataShop){
      getbrands(dataShop.country_id, dataShop.catalog_id).then(setBrands)
    }
  },[dataShop])

  // Carga los datos del carrito en el contexto

  useEffect(() => {
    if(sucursal !== null && window) {
      if (getLocalStorage(localNames.cart) === null || localStorage.getItem('shop') !== sucursal) {
        setLocalStorage(localNames.cart, [])
        localStorage.setItem('total', 0);
        localStorage.setItem('detalle', '');
        localStorage.setItem('cantidadproductos', 0);
      } else {
        setCantidad(parseInt(localStorage.getItem('cantidadproductos')));
        setTotal(parseFloat(localStorage.getItem('total')));
        setCarrito(getLocalStorage(localNames.cart));
        setActualizar(false);
      }
      setCargandoCarrito(false);
    }
  }, [cargandoCarrito, actualizar, sucursal]);

  useEffect(() => {
    if(sucursal !== null) {
      if (getLocalStorage(localNames.close_banner) === null) {
          setLocalStorage(localNames.close_banner, true);
          setIsShowBanner(JSON.parse(localStorage.getItem('close_banner')));
      }
      if (getLocalStorage(localNames.close_banner) === false) {
          setLocalStorage(localNames.close_banner, false);
          setIsShowBanner(JSON.parse(localStorage.getItem('close_banner')));
      } else {
        setIsShowBanner(localStorage.getItem('close_banner'));
      }
    }
  }, [sucursal]);

  // Actualizar la variable Lines

  useEffect(() => {
    let array = []
    carrito?.map(singleCarrito => {
      let object = {
        product_id: singleCarrito.productid,
        quantity: {
          value: singleCarrito.cantidad.toString()
        },
        notes: singleCarrito?.notes ?? '',
      }
      array.push(object)
    })
    setLines(array)
  }, [carrito])

  // Borrar un producto del carrito

  const handleBorrar = (productid, cantidaditem, subtotalitem, e) => {
    e?.stopPropagation();
    let carritoActual = getLocalStorage(localNames.cart)
    let nuevoDetalle = '';
    carritoActual.forEach((element, index) => {
      if (element.productid === productid) {
        //se borra el producto del carrito
        carritoActual.splice(index, 1);
      }
    });

    carritoActual.forEach((element, index) => {
      nuevoDetalle =
        index === 0
          ? nuevoDetalle +
            `${
              element.medida === 'gr'
                ? element.cantidad * element.escala < 1000
                  ? element.cantidad * element.escala
                  : (element.cantidad * element.escala) / 1000
                : element.cantidad * element.escala
            } ${
              element.medida === 'gr' &&
              element.cantidad * element.escala >= 1000
                ? 'kg'
                : element.medida
            } x ${element.nombre}`
          : nuevoDetalle +
            ` | ${
              element.medida === 'gr'
                ? element.cantidad * element.escala < 1000
                  ? element.cantidad * element.escala
                  : (element.cantidad * element.escala) / 1000
                : element.cantidad * element.escala
            } ${
              element.medida === 'gr' &&
              element.cantidad * element.escala >= 1000
                ? 'kg'
                : element.medida
            } x ${element.nombre}`;
    });
    localStorage.setItem(
      'total',
      parseFloat(((total * 100 - subtotalitem * 100) / 100).toFixed(2))
    );
    localStorage.setItem('cantidadproductos', cantidad - cantidaditem);
    setCarrito(carritoActual);
    setCantidad(cantidad - cantidaditem);
    setTotal(Math.round((parseFloat(((total * 100 - subtotalitem * 100) / 100).toFixed(2))) * 100) / 100);
    localStorage.setItem('detalle', nuevoDetalle);
    localStorage.setItem('carrito', JSON.stringify(carritoActual));
    if (cantidad === cantidaditem) {
      setEstadoBarra('volver');
    }
    setActualizar(true);
  };

  // Agregar un producto al carrito y actualizarlo

  const nuevoCarrito = (item) => {
    let newElement;
    let carritoActual = getLocalStorage(localNames.cart) ?? [];
    let detalleActual = localStorage.getItem('detalle');
    // Se fuerza el borrado de GTM de local storage para limpiar la memoria
    localStorage.removeItem('DataLayerHistory')

    if (carritoActual?.length === 0) {
      //primer producto agregado//
      carritoActual.push(item);
      setLocalStorage(localNames.cart, carritoActual)
      localStorage.setItem('total', item.subtotal);
      localStorage.setItem(
        'detalle',
        `${
          item.medida === 'gr'
            ? item.cantidad * item.escala < 1000
              ? item.cantidad * item.escala
              : (item.cantidad * item.escala) / 1000
            : item.cantidad * item.escala
        } ${
          item.medida === 'gr' && item.cantidad * item.escala >= 1000
            ? 'kg'
            : item.medida
        } x ${item.nombre}`
      );
      localStorage.setItem('cantidadproductos', item.cantidad);
      setCantidad(item.cantidad);
      setTotal(Math.round((parseFloat(item.subtotal)) * 100) / 100);
      setActualizar(true);
    } else {
      //ya existe un producto en el carrito
      let existe = false;
      let nuevoDetalle = '';
      carritoActual.forEach((element, index) => {
        if (element.productid === item.productid) {
          //se va a agregar un producto que ya está en el carrito
          existe = true;
          newElement = {
            cantidad: element.cantidad + item.cantidad,
            categoria: item.categoria,
            nombre: item.nombre,
            imagen: item.imagen,
            precio: parseFloat(item.precio),
            productid: item.productid,
            subtotal: parseFloat(
              ((element.subtotal * 100 + item.subtotal * 100) / 100).toFixed(2)
            ),
            medida: element.medida,
            escala: element.escala,
            datos_producto: element.datos_producto,
            notes: item.notes,
          };
          carritoActual.splice(index, 1, newElement);
          setLocalStorage(localNames.cart, carritoActual)
          localStorage.setItem(
            'total',
            parseFloat(((total * 100 + item.subtotal * 100) / 100).toFixed(2))
          );
          localStorage.setItem('cantidadproductos', cantidad + item.cantidad);
          nuevoDetalle =
            index === 0
              ? nuevoDetalle +
                `${
                  element.medida === 'gr'
                    ? (element.cantidad + item.cantidad) * element.escala < 1000
                      ? (element.cantidad + item.cantidad) * element.escala
                      : ((element.cantidad + item.cantidad) * element.escala) /
                        1000
                    : (element.cantidad + item.cantidad) * element.escala
                } ${
                  item.medida === 'gr' &&
                  (element.cantidad + item.cantidad) * item.escala >= 1000
                    ? 'kg'
                    : item.medida
                } x ${item.nombre}`
              : nuevoDetalle +
                ` | ${
                  element.medida === 'gr'
                    ? (element.cantidad + item.cantidad) * element.escala < 1000
                      ? (element.cantidad + item.cantidad) * element.escala
                      : ((element.cantidad + item.cantidad) * element.escala) /
                        1000
                    : (element.cantidad + item.cantidad) * element.escala
                } ${
                  item.medida === 'gr' &&
                  (element.cantidad + item.cantidad) * item.escala >= 1000
                    ? 'kg'
                    : item.medida
                } x ${item.nombre}`;
          setCantidad(cantidad + item.cantidad);
          setTotal(
            Math.round((parseFloat(((total * 100 + item.subtotal * 100) / 100).toFixed(2))) * 100) / 100
          );
        } else {
          nuevoDetalle =
            index === 0
              ? nuevoDetalle +
                `${
                  element.medida === 'gr'
                    ? element.cantidad * element.escala < 1000
                      ? element.cantidad * element.escala
                      : (element.cantidad * element.escala) / 1000
                    : element.cantidad * element.escala
                } ${
                  element.medida === 'gr' &&
                  (element.cantidad + element.cantidad) * element.escala >= 1000
                    ? 'kg'
                    : element.medida
                } x ${element.nombre}`
              : nuevoDetalle +
                ` | ${
                  element.medida === 'gr'
                    ? element.cantidad * element.escala < 1000
                      ? element.cantidad * element.escala
                      : (element.cantidad * element.escala) / 1000
                    : element.cantidad * element.escala
                } ${
                  element.medida === 'gr' &&
                  (element.cantidad + element.cantidad) * element.escala >= 1000
                    ? 'kg'
                    : element.medida
                } x ${element.nombre}`;
        }
      });

      if (!existe) {
        //se va a agregar un producto que no está en el carrito
        carritoActual.push(item);
        setLocalStorage(localNames.cart, carritoActual)
        localStorage.setItem('total', parseFloat(total + item.subtotal));
        localStorage.setItem(
          'detalle',
          detalleActual + ` | ${item.cantidad} ${item.medida} x ${item.nombre}`
        );
        localStorage.setItem('cantidadproductos', cantidad + item.cantidad);
        setCantidad(cantidad + item.cantidad);
        setTotal(
          Math.round((parseFloat(((total * 100 + item.subtotal * 100) / 100).toFixed(2))) * 100) / 100
        );
      } else {
        localStorage.setItem('detalle', nuevoDetalle);
      }
      setActualizar(true);
    }
  };

  // Define los estados de la barra de carrito

  const handleInicial = () => {
    cantidad === 0 ? setEstadoBarra('volver') : setEstadoBarra('continuar');
  };

  const handleContinuar = () => {
    setEstadoBarra('finalizar');
  };

  const handleVolver = () => {
    setEstadoBarra('inicial');
  };

  // Grabar el pedido cuando el usuario confirma
  const handleSetOrder = async (usesWallet, selectedDate, paymentOnline) => {
    let respuesta
    const object = await setOrderObject(datosComprador, user, dataShop, fechaEntrega, lines, couponValidated, isSG, pais, selectedDate, selectedDeliveryPoint, userTier)
    await setorder(object, dataShop?.on_fulfillment_start ? dataShop?.on_fulfillment_start === 'autoconfirm' : false)
    .then(async res => {
        if(res.status === 200) {
          let message_to_client = await getMessageToClient(res.data.order, carrito, datosComprador, selectedDeliveryPoint ?? communityLeader, selectedDeliveryPoint, dataShop.slug)
          let message_to_community_leader = await order_confirmation_to_community_leader(res.data.order, datosComprador, selectedDeliveryPoint ?? dataShop, selectedDeliveryPoint)
            localStorage.setItem(
              'ultimopedido',
              JSON.stringify({order: res.data.order, lines: carrito, customer: {
                first_name: datosComprador.nombre.toLowerCase(),
                last_name: datosComprador.apellido.toLowerCase(),
                phone_number: datosComprador.telefono
              }})
            );
            setUltimoPedido({order: res.data.order, lines: carrito, customer: {
              first_name: datosComprador.nombre.toLowerCase(),
              last_name: datosComprador.apellido.toLowerCase(),
              phone_number: datosComprador.telefono
            }});
            if(!isSG) {
              sendmessage(datosComprador.telefono, message_to_client)
              sendmessage(selectedDeliveryPoint?.phone ?? communityLeader.phone, message_to_community_leader)
            }
            if(isSG && customer.customer.customer_sub_type_id === 2) {
              sendmessage(datosComprador.telefono, message_to_client)
            }
            let link = null
            if(paymentOnline) {
              const payment = {
                date: new Date(),
                community_leader_id: selectedDeliveryPoint.community_leader_id ?? communityLeader.id,
                order_code: res.data.order.code,
                amount: parseFloat(res.data.order.total.value),
                status: '',
                is_manual: false,
                type: 'mercadopago',
                country_code: countryCode[pais],
                source_application: 'nilus',
                title: `Pago de pedido #${res.data.order.code} | ${selectedDeliveryPoint ? `${selectedDeliveryPoint.slug} en ${dataShop.slug}` : dataShop.slug}`,
                shop_ckeckout: true
              }
              const mercadoPago = await getpaymentlink(payment, dataShop.slug, 'mercadopago', selectedDeliveryPoint ?? communityLeader, res.data.order.id)
              link = mercadoPago.init_point
            }
            setCarrito([]);
            setEstadoBarra('inicial');
            setTotal(0);
            setCantidad(0);
            localStorage.setItem('total', 0);
            localStorage.setItem('cantidadproductos', 0);
            setLocalStorage(localNames.cart, [])
            localStorage.setItem('detalle', '');
            localStorage.removeItem('coupon');
            localStorage.removeItem('coupon_minimum_purchase_value');
            localStorage.removeItem('coupon_value');
            localStorage.removeItem('showLastRecommendedProducts');
            respuesta = { order: res, payment_link: link };
            deleteUserCookie('nilus_tier_id')
            if(customer?.customer?.tier_id) {
              setCookie('nilus_last_user_tier', customer?.customer?.tier_id, 60 * 60 * 24 * 60)
              setUserTier(customer?.customer?.tier_id)
            }
        }
        // status 400 es error de cupon
        if(res.status === 400) {
          if(res.error_code === 2) {
            setValueForMinimumPurchase(parseFloat(res.value))
            setCouponErrorCode(res)
            trackOrderNotSent(currentInfoCountry.currency_code, total, carrito, couponValidated, res.error, res.error_code)
            localStorage.setItem('coupon_minimum_purchase_value', res.value)
            respuesta = res
          } else {
            const couponErrors = [5 /*coupon not valid*/, 4, 8, 16, 128]
            if(couponErrors.includes(res.error_code)) {
              setCouponErrorAtCheckout(true)
              trackOrderNotSent(currentInfoCountry.currency_code, total, carrito, res.coupon_code, res.error, res.error_code)
              setCouponErrorCode(res)
              respuesta = res
            } else {
              trackOrderNotSent(currentInfoCountry.currency_code, total, carrito, couponValidated, res.error, res.error_code)
              respuesta = false
            }
          }
        }
    })
    return respuesta
  };

  useEffect(() => {
    if((customer || refetchWallet) && ((dataShop?.customer_type_id !==3 && customer?.customer?.inswitch_entity_id)|| (dataShop?.customer_type_id === 3 && customer?.customer?.customer_sub_type_id === 2 && customer?.customer?.inswitch_entity_id))) {
      getWalletBalance(customer?.customer?.id).then(setWalletAmount);
      getWalletMovements(customer?.customer?.id).then(res => {
        setWalletTransactions(res);
        setRefetchWallet(false);
      })
    }
  }, [customer, refetchWallet, dataShop]);

  return (
    <Provider
      value={{
        nuevoCarrito,
        carrito,
        cargandoCarrito,
        cantidad,
        total,
        estadoBarra,
        handleInicial,
        handleContinuar,
        handleVolver,
        actualizar,
        setActualizar,
        handleBorrar,
        datosComprador,
        setDatosComprador,
        handleSetOrder,
        pais,
        setPais,
        setDatosComprador,
        dataShop,
        setDataShop,
        productos,
        setProductos,
        sucursal,
        setSucursal,
        loading,
        setLoading,
        ultimoPedido,
        fechaEntrega,
        spinner,
        setSpinner,
        categories,
        banners,
        lines,
        currentInfoCountry,
        communityLeader,
        setReFetchNextDeliveryDate,
        isSG,
        user,
        step,
        setStep,
        setUser,
        router,
        couponValidated,
        setCouponValidated,
        valueForMinimumPurchase,
        setValueForMinimumPurchase,
        couponErrorCode,
        setCouponErrorCode,
        couponErrorAtCheckout,
        setCouponErrorAtCheckout,
        isCatalogSG,
        setCarrito, 
        setCantidad,
        setTotal,
        customer,
        setCustomer,
        openDrawer,
        setOpenDrawer,
        isSnackBarShown,
        setIsSnackBarShown,
        setProductosEnCategoria,
        productosEnCategoria,
        deleteProducts,
        setDeleteProducts,
        promotionsCarouselId, 
        setPromotionsCarouselId,
        productsHighlights,
        setProductsHighlights,
        productsHighlightsTitle,
        setProductsHighlightsTitle,
        setRefreshUser,
        walletAmount,
        setWalletAmount,
        walletTransactions,
        setWalletTransactions,
        refetchWallet, 
        setRefetchWallet,
        setIsShowBanner,
        isShowBanner,
        minimumOrderValue,
        brands,
        setProductsByBrand,
        productsByBrand,
        bannerSlider,
        bannerHero,
        bannerInApp,
        deliveryPointSelector, 
        setDeliveryPointSelector,
        selectedDeliveryPoint, 
        setSelectedDeliveryPoint,
        modalDeliveryPointSelector, 
        setModalDeliveryPointSelector,
        modalDateSelector, 
        setModalDateSelector,
        userTier,
        profilePicture,
        setProfilePicture,
        nearestShops,
        actualLocation,
        sharedLocation,
        isDefaultTier,
        showTierAdvice,
        setShowTierAdvice,
        setActualLocation,
        setNearestShops,
        featureFlags,
      }}
    >
      {children}
    </Provider>
  );
}

export { CarritoProvider, Consumer as CarritoConsumer, Context };
