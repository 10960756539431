import { localeCode } from "./helpers";

export default function formatNumber(number, country) {
  const locale = localeCode[country] || 'en-US';
  const roundedNumber = Math.round(number * 100) / 100;
  const isInteger = Number.isInteger(roundedNumber);

  return isInteger
    ? roundedNumber.toLocaleString(locale)
    : roundedNumber.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
}
