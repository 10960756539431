import nameunitmeasure from "./nameunitmeasure"

export const hasDiscount = (product) => {
    if (!product || typeof product !== 'object') {
      return false;
    }
  
    const hasProductDiscount = product['product-discount'] !== undefined;

    const hasLocalPriceDiscount =
      product.local_price !== null &&
      typeof product.local_price === 'number' &&
      typeof product.price === 'number' &&
      product.local_price > product.price &&
      product.local_price > 0;
  
    return hasProductDiscount || hasLocalPriceDiscount;
  };

export const finalDiscount = (product) => {
  if (!product || typeof product !== 'object') {
    return 0;
  }

  const { local_price = 0, price = 0, 'product-discount': productDiscount } = product;
  
  if (typeof price !== 'number' || price <= 0) {
    return 0;
  }
  
  const basePrice = local_price > price ? local_price : price;

  const discountPercentage = productDiscount?.discount_percentage ?? 0;

  const discountedPrice = price * (1 - discountPercentage / 100);

  const discount = ((basePrice - discountedPrice) / basePrice) * 100;

  return Math.round(discount);
};


export const finalPrice = (product) => {
    if (!product || typeof product.price !== 'number') {
      return 0;
    }
    const discount = product['product-discount']?.discount_percentage ?? 0;
    return product.price * (1 - discount / 100);
  };

  export const totalDiscountNumber = (product) => {
    if (!product || typeof product.price !== 'number' || product.price <= 0) {
      return 0;
    }
  
    const discountedPrice = finalPrice(product);
    if (isNaN(discountedPrice) || discountedPrice <= 0) {
      return 0;
    }
    return product.price - discountedPrice;
  };
  

const fixScale = (quantity, scale) => {
    // words to array
    const scaleArray = scale.split(' ')
    if(scaleArray[0] === 'caja' && quantity > 1) {
        scaleArray[0] = 'cajas'
    }
    if(scaleArray[0] === 'paquete' && quantity > 1) {
        scaleArray[0] = 'paquetes'
    }
    if(scaleArray[0] === 'pieza' && quantity > 1) {
        scaleArray[0] = 'piezas'
    }
    if(scaleArray[0] === 'unidad' && quantity > 1) {
        scaleArray[0] = 'unidades'
    }
    if(scaleArray[0] === 'docena' && quantity > 1) {
        scaleArray[0] = 'docenas'
    }
    return scaleArray.join(' ')
}

export const measureUnit = (quantity, product) => {
    const newUnitMeasure = fixScale(quantity, nameunitmeasure(product.unit_of_measurement.erp_name))
    const string = `${quantity} ${product.scale > 1 ? `x${product.scale}`: ''} ${newUnitMeasure}`
    return string
}