const { normalizePhone } = require("componentes/UserPhone/helpers")

const parseDate = (dateString) => {
    const date = new Date(dateString);
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const dateFormat = new Intl.DateTimeFormat('en-US', dateOptions);
    const dateParts = dateFormat.formatToParts(date);
    
    let parsedDate = {};
    dateParts.forEach(part => {
        if (part.type === 'year') {
        parsedDate.year = parseInt(part.value);
        } else if (part.type === 'month') {
        parsedDate.month = parseInt(part.value); // Restamos 1 para obtener el número de mes de 0 a 11
        } else if (part.type === 'day') {
        parsedDate.day = parseInt(part.value);
        }
    });
    
    return parsedDate;
  }

const setOrderObject = async (datosComprador, user, dataShop, fechaEntrega, lines, couponValidated, isSG, country, selectedDate, deliveryPoint, tierId) => {
    let object
    if(!isSG && !user) {
        object = {
            guest_customer: {
                 first_name: datosComprador.nombre.toLowerCase(),
                 last_name: datosComprador.apellido.toLowerCase(),
                 phone_number: datosComprador.telefono.charAt(0) !== '+' ? `+${normalizePhone(country, datosComprador.telefono)}` : normalizePhone(country, datosComprador.telefono)
             },
            shop_id: dataShop.id,
            delivery_address_id: dataShop.address_id,
            payment_method_id: datosComprador?.payment_id,
            selected_delivery_date: selectedDate ? parseDate(selectedDate) : {
                    year: fechaEntrega.year,
                    month: fechaEntrega.month,
                    day: fechaEntrega.day
                },
            lines: lines,
            coupon_code: couponValidated,
            is_donation: false,
            notes: datosComprador.comentario,
            require_debit_from_wallet: false,
            tier_id: tierId ?? null
         }
    } else {
        object = {
            customer_id: user.customer_id,
            shop_id: dataShop.id,
            delivery_address_id: deliveryPoint ? deliveryPoint?.address_id : dataShop.address_id,
            payment_method_id: datosComprador?.payment_id ?? 1,
            selected_delivery_date: selectedDate ? parseDate(selectedDate) : {
                year: fechaEntrega.year,
                month: fechaEntrega.month,
                day: fechaEntrega.day
            },
            lines: lines,
            coupon_code: couponValidated,
            is_donation: false,
            notes: datosComprador.comentario,
            tier_id: tierId ?? null,
            //require_debit_from_wallet: usesWallet
        }
        if(deliveryPoint) {
            object.acting_community_leader_id = deliveryPoint?.community_leader_id
        }
    }
    return object
}

export default setOrderObject