import { localNames } from "./helpers"

export default async function getUser() {
    const local_token = localStorage.getItem(localNames.token)
    const url = local_token ? `/api/user?token=${local_token}` : `/api/user`
    const response = await fetch(url)
    const data = await response.json()
    if(data.error) localStorage.removeItem(localNames.token)
    return data
}
   
